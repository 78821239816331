<template>
  <div>
    <div v-if="!$apollo.loading">
      <b-card title="Busque um grupo 🔍">
        <b-row>
          <b-col
            cols="6"
            lg="6"
          >
            <b-form-group>
              <label for="group-type">No campo abaixo busque por nome</label>

              <b-form-input
                id="live-query"
                v-model="searchQuery"
                icon-no-border
                placeholder="Digite e pressione ENTER"
                class="input-rounded-full"
                icon="icon-search"
                icon-pack="feather"
                :state="docState"
                aria-describedby="input-live-help input-live-feedback"
                @keyup.enter.native="searchUser()"
              />
              <b-form-invalid-feedback id="input-live-feedback">
                Pelo menos 3 letras
              </b-form-invalid-feedback>
            </b-form-group>

          </b-col>
          <b-col
            cols="6"
            lg="3"
          >
            <b-form-group>

              <label for="group-type">Estado</label>
              <b-form-select
                v-model="filterStatus"
                size="lg"
                @change="searchUser()"
                @click="searchUser()"
              >
                <b-form-select-option
                  value=""
                >
                  Todos
                </b-form-select-option>
                <b-form-select-option
                  v-for="item in statuses"
                  :key="item.id"
                  :data="item"
                  :value="item.id"
                  @click="searchUser()"
                >
                  {{ item.name }}
                </b-form-select-option>
              </b-form-select>
            </b-form-group>

          </b-col>
        </b-row>
      </b-card>
      <general-groups-list
        :searched-groups="queryResult"
        :context-actions="'manageGroup'"
      />
    </div>
    <div v-else>
      <logo-loader-animation />
    </div>
  </div>

</template>

<script>
import {
  BCard, BCardText, BLink, BFormInput, BFormInvalidFeedback, BFormSelect, BRow, BCol, BFormSelectOption, BFormGroup,
} from 'bootstrap-vue'
import GeneralGroupsList from '@/components/groups/GeneralGroupsList.vue'
import gql from 'graphql-tag'
import { _ } from 'vue-underscore'
import LogoLoaderAnimation from '@/components/misc/LogoLoaderAnimation.vue'

export default {
  components: {
    BFormGroup,
    BFormSelectOption,
    BFormSelect,
    BRow,
    BCol,
    BCard,
    BCardText,
    BLink,
    BFormInput,
    GeneralGroupsList,
    BFormInvalidFeedback,
    underscore: _,
    LogoLoaderAnimation,

  },
  data() {
    return {
      searchQuery: '',
      currentPage: 1,
      searchResults: [],
      queryResult: null,
      filterStatus: 'ACTIVE',
      statuses: [{ id: 'ACTIVE', name: 'ativo' },
        { id: 'INACTIVE', name: 'inativo' }],
    }
  },
  computed: {
    docState() {
      return this.searchQuery.length > 2
    },
  },
  created() {
    this.searchUser()
  },
  methods: {

    searchUser() {
      if (this.searchQuery == '' || !document.getElementById('live-query').getAttribute('aria-invalid')) {
        this.$apollo.query({
          query: gql`query($value: String!, $status: String){
            user_groups_by_name(
              name : $value,
              status: $status
            ){
              id,
              name,
              status,
              members_count,
              created_at
            }
          }`,
          variables: {
            value: `%${this.searchQuery}%`,
            status: this.filterStatus != '' ? this.filterStatus : undefined,
          },
          client: 'identityClient',
        }).then(data => {
          this.queryResult = data.data.user_groups_by_name
          // this.queryResult = _.pluck(this.queryResult, 'user');
        }).catch(error => {
          console.error(error)
        })
      }
    },
  },
}
</script>

<style>

</style>
